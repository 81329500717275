// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://api.kino.bet/',
  apiUrl: 'https://api.kino.bet/api/v1/',
  claroUrl: 'https://api.kino.bet/',
  claroApiUrl: 'https://api.kino.bet/api/v1/',
  actions: {
    login: 'auth-web',
    me: 'me',
    all: 'all',
    bySport: 'by-sport',
    byPool: 'by-pool',
    show: 'show',
    store: 'store',
    storeScore: 'store-score',
    update: 'update',
    activate: 'active',
    deactivate: 'desactive',
    delete: 'delete',
    myPools: 'my-admin-pools',
    updateByReseller: 'update-by-reseller',
    deleteUser: 'delete-user',
    import: 'import-excel',
    countedList: 'counted-list',
    positions: 'positions',
    getResellers: 'get-resellers',
    updateByAdmin: 'update-by-admin',
    updateNicknameByAdmin: 'update-nickname-by-admin',
    updateMyProfile: 'update-profile',
    changePassword: 'change-password',
    inactive: 'inactive'
  },
  EMAIL_REGEX: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
